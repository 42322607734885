<template>
  <div class="container px20">
    <div class="row between-xs middle-xs inner-height" v-if="!isCheckoutPage || isThankYouPage">
      <div class="visible-xs-and-tablet col-xs-2 center-xs">
        <div>
          <hamburger-icon class="p15 icon pointer" />
        </div>
      </div>
      <div class="col-xs-2 visible-xs-and-tablet center-xs pl0 pr0">
        <account-icon class="p15 icon hidden-md pointer" />
      </div>
      <div class="visible-xs-and-tablet col-xs-2 center-xs pl0 pr0 search-mobile-icon">
        <mic-icon class="p15 icon pointer hidden-md" />
      </div>
      <div class="col-xs-2 visible-xs-and-tablet center-xs pl0 pr0">
        <wishlist-icon class="p15 icon pointer" />
      </div>
      <div class="visible-xs-and-tablet col-xs-2 center-xs p0">
        <div class="inline-flex">
          <microcart-icon class="p15 icon pointer" />
        </div>
      </div>
      <div class="row inner-desktop w-100">
        <logo width="100%" height="32px" class="col-xs-2 px20" />
        <div class="col-xs-10 flex end-xs middle-xs">
          <account-icon class="pointer no-outline" :class="{'opacity-hover': !currentUser}" />
          <div class="flex middle-xs pl20 opacity-hover">
            <i class="icon-phone h3 cl-alternative" />
            <div class="pl10 start-sm">
              <a class="cl-alternative weight-500 no-underline" href="tel:22-397-43-92">22 397 43 92</a>
              <p class="m0 infolinia cl-matterhorn">
                INFOLINIA 9-18
              </p>
            </div>
          </div>
          <compare-icon class="pointer pl10 opacity-hover" />
          <wishlist-icon class="pointer pl10 opacity-hover" />
          <microcart-icon class="pointer pl10 opacity-hover" />
        </div>
      </div>
    </div>
    <div
      class="row between-xs middle-xs py5 checkout-header"
      v-if="!isThankYouPage && isCheckoutPage"
    >
      <div class="col-xs-5 col-md-3 middle-xs pl0">
        <div>
          <router-link
            :to="localizedRoute('/')"
            class="cl-accent no-underline"
          >
            {{ $t('Return to shopping') }}
          </router-link>
        </div>
      </div>
      <div class="col-xs-2 col-md-6 center-xs flex">
        <logo width="100%" height="28px" />
      </div>
      <div class="col-xs-5 col-md-3 end-xs pr0">
        <div class="hidden-xs-and-tablet">
          <a
            v-if="!currentUser"
            href="#"
            @click.prevent="gotoAccount"
            class="cl-accent links no-underline"
          >{{ $t('Login to your account') }}</a>
          <span v-else>{{ $t('You are logged in as') }} {{ currentUser.firstname }}</span>
        </div>
        <div class="visible-xs-and-tablet lh-12">
          <img height="24px" width="auto" src="\assets\certum.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CurrentPage from 'theme/mixins/currentPage';
import Logo from 'theme/components/core/Logo';

const AccountIcon = () => import(/* webpackChunkName: "vsf-account-icon" */ 'theme/components/core/blocks/Header/AccountIcon')
const CompareIcon = () => import(/* webpackChunkName: "vsf-compare-icon" */ 'theme/components/core/blocks/Header/CompareIcon')
const HamburgerIcon = () => import(/* webpackChunkName: "vsf-hamburger-icon" */ 'theme/components/core/blocks/Header/HamburgerIcon')
const MicrocartIcon = () => import(/* webpackChunkName: "vsf-microcart-icon" */ 'theme/components/core/blocks/Header/MicrocartIcon')
const MicIcon = () => import(/* webpackChunkName: "vsf-mic-icon" */ 'theme/components/core/blocks/Header/MicIcon')
const WishlistIcon = () => import(/* webpackChunkName: "vsf-wishlist-icon" */ 'theme/components/core/blocks/Header/WishlistIcon')

export default {
  name: 'InnerHeader',
  components: {
    AccountIcon,
    CompareIcon,
    HamburgerIcon,
    Logo,
    MicrocartIcon,
    WishlistIcon,
    MicIcon
  },
  mixins: [CurrentPage],
  computed: {
    ...mapState({
      isOpenLogin: state => state.ui.signUp,
      currentUser: state => state.user.current
    }),
    isThankYouPage () {
      return this.$store.state.checkout.isThankYouPage
        ? this.$store.state.checkout.isThankYouPage
        : false;
    }
  },
  methods: {
    gotoAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-icon-hover: color(secondary, $colors-background);

.inner-desktop {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
    display: none;
  }
}
.inner-height {
  height: 60px;
  .infolinia {
    font-size: 11px;
  }
}
.links {
  text-decoration: underline;
}
.lh-12 {
  line-height: 12px;
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px) {
  .checkout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .checkout-header > div {
    height: 12px;
    margin-top: 5px;
  }
}
.opacity-hover {
  &:hover {
    opacity: 0.7;
  }
}
</style>
